export const contactFormStrings = {
  FILL_ALL_FIELDS_ERROR: "Please fill all required fields.",
  NAME_PLACEHOLDER: "First and last name",
  COMPANY_NAME_PLACEHOLDER: "Company name",
  COMPANY_EMAIL_PLACEHOLDER: "E-mail",
  COMPANY_PHONE_PLACEHOLDER: "Telephone",
  CONTENT_PLACEHOLDER: "Message content",
  COMPANY_SIZE_SELECT_DEF_NAME: "Company size",
  COMPANY_SIZE_1_NAME: "Sole proprietorship",
  COMPANY_SIZE_2_NAME: "Micro (up to 10 employees)",
  COMPANY_SIZE_3_NAME: "Small (up to 49 employees)",
  COMPANY_SIZE_4_NAME: "Medium (up to 249 employees)",
  COMPANY_SIZE_5_NAME: "Large (250 employees or more)",
  CONSENT_SHORT: "I agree to the processing of personal data",
  CONSENT_LONG: `I agree to the processing by Questy - Wdrożenia i Wsparcie IT Sp. z o.o. with its registered office in Poznań, 
  ul. Głogowska 31/33, 60-702 Poznań of my personal data contained in this form in order to receive an answer to 
  the question asked. Your data will be stored until you withdraw your consent. You have access to personal data 
  being processed and correcting, as well as the right to request cessation of data processing, objecting and 
  deleting them at any time without affecting the lawfulness of processing by sending an email to kontakt@questy.pl 
  from the address which is about consent. Providing personal data is voluntary, however, refusing to process it 
  prevents us from handling your application.`,
  FORM_SENDING: "Submitting...",
  FORM_SEND: "Submit",
};
