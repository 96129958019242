import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FaQuestionCircle } from "react-icons/fa";
import { load } from "recaptcha-v3";
import { CONTACT_FORM_TIMEOUT, RECAPTCHA_SITE_TOKEN } from "../consts";
import { contactFormStrings as translations } from "../translations";
import { getAddNewUrl } from "../utils";
import * as styles from "./ContactForm.module.css";

const ContactForm = ({ systemType }) => {
  const { register, handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;
  const recaptchaRef = useRef(null);
  const [gaCookie, setGaCookie] = useState("");
  const [utmCookie, setUtmCookie] = useState("");
  const [utmMediumCookie, setUtmMediumCookie] = useState("");
  const [utmCampaignCookie, setUtmCampaignCookie] = useState("");
  const [gclidParam, setGclidParam] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const cookies = Cookies.get();
    setGaCookie(cookies["_ga"] ?? "");
    setUtmCookie(cookies["utm_source"] ?? "");
    setUtmMediumCookie(cookies["utm_medium"] ?? "");
    setUtmCampaignCookie(cookies["utm_campaign"] ?? "");
    setGclidParam(cookies["gclid"] ?? "");
  });

  // Ładowanie bilioteki recaptcha po wejściu na strone z formularze
  useEffect(() => {
    async function loadRecaptcha() {
      const recaptcha = await load(RECAPTCHA_SITE_TOKEN);
      recaptchaRef.current = recaptcha;
    }
    loadRecaptcha();
  }, []);

  const onSubmit = async (data) => {
    const preparedData = {
      ...data,
      gclid: gclidParam,
      system_type: systemType,
      ga_value: gaCookie,
      utm_source: utmCookie,
      utm_medium: utmMediumCookie,
      utm_campaign: utmCampaignCookie,
      g_recaptcha_response:
        (await recaptchaRef.current?.execute("contact_form")) ?? "", // pobranie tokena do weryfikacji zapytania recpatcha
    };

    try {
      const { data } = await axios.post(getAddNewUrl(), preparedData, {
        headers: {
          Accept: "application/json",
        },
        timeout: CONTACT_FORM_TIMEOUT,
      });

      if (data?.location) {
        window.location.href = data.location;
      }
    } catch (err) {
      if (err.response) {
        if (err.response?.data?.location) {
          window.location.href = err.response.data.location;
        }

        if (
          err.response.status === 400 &&
          err.response?.data?.message === "Please fill all required fields"
        ) {
          alert(translations.FILL_ALL_FIELDS_ERROR);
        }
      } else {
        window.location.href = "/ups";
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.contactForm}>
      <input ref={register} type="hidden" name="contact_form" value="1" />
      <input
        ref={register}
        required
        type="text"
        name="contact_form_name"
        placeholder={translations.NAME_PLACEHOLDER}
      />
      <input
        ref={register}
        required
        type="text"
        name="contact_form_company_name"
        placeholder={translations.COMPANY_NAME_PLACEHOLDER}
      />
      <select
        ref={register}
        defaultValue="nie wybrano"
        required
        name="contact_form_company_size"
        data-testid="contact_form_company_size"
      >
        <option data-testid="jednoosobowa" value="jednoosobowa">
          {translations.COMPANY_SIZE_1_NAME}
        </option>
        <option data-testid="mikro" value="mikro">
          {translations.COMPANY_SIZE_2_NAME}
        </option>
        <option data-testid="mała" value="mała">
          {translations.COMPANY_SIZE_3_NAME}
        </option>
        <option data-testid="średnia" value="średnia">
          {translations.COMPANY_SIZE_4_NAME}
        </option>
        <option data-testid="duża" value="duża">
          {translations.COMPANY_SIZE_5_NAME}
        </option>
        <option value="nie wybrano" disabled>
          {translations.COMPANY_SIZE_SELECT_DEF_NAME}
        </option>
      </select>
      <input
        ref={register}
        required
        type="text"
        name="system_email"
        placeholder={translations.COMPANY_EMAIL_PLACEHOLDER}
      />
      <input
        ref={register}
        type="text"
        name="contact_form_phone"
        placeholder={translations.COMPANY_PHONE_PLACEHOLDER}
      />
      <textarea
        ref={register}
        name="contact_form_content"
        cols="30"
        rows="1"
        placeholder={translations.CONTENT_PLACEHOLDER}
      />
      <div className={`${styles.consentTooltip}`}>
        <input
          ref={register}
          required
          type="checkbox"
          name="consent"
          id={styles.consent}
        />
        <label id={styles.consentLabel} htmlFor={styles.consent}>
          {translations.CONSENT_SHORT}{" "}
          <FaQuestionCircle className={styles.questionIcon} size={12} />
        </label>
        <p className={styles.tooltiptext}>{translations.CONSENT_LONG}</p>
      </div>
      <button
        disabled={isSubmitting}
        className={styles.contactForm__submitBtn}
        type="submit"
      >
        {isSubmitting ? translations.FORM_SENDING : translations.FORM_SEND}
      </button>
    </form>
  );
};

export default ContactForm;
